import type { StateCreator } from 'zustand'

import { INITIAL_ALL_GAMES } from '../../constants/casino'
import type { AllSlices } from '../types'
import type { CasinoSlice } from '../types/casino'

export const createCasinoSlice: StateCreator<
  AllSlices,
  [],
  [],
  CasinoSlice
> = set => ({
  allGames: INITIAL_ALL_GAMES,
  setCategoryGames: (categoryKey, categoyGames, fresh) => {
    set(state => ({
      casino: {
        ...state.casino,
        allGames: {
          ...state.casino.allGames,
          [categoryKey]: fresh
            ? categoyGames
            : state.casino.allGames?.[categoryKey]
            ? [...state.casino.allGames[categoryKey], ...categoyGames]
            : categoyGames,
        },
      },
    }))
  },
  clearCategoryGames: categoryKey => {
    set(state => ({
      casino: {
        ...state.casino,
        allGames: {
          ...state.casino.allGames,
          [categoryKey]: [],
        },
      },
    }))
  },
  categories: [],
  providers: null,
  setCategories: categories => {
    set(state => ({
      casino: { ...state.casino, categories },
    }))
  },
  setProviders: providers => {
    set(state => ({
      casino: { ...state.casino, providers },
    }))
  },
  loadMoreLoading: false,
  setLoadMoreLoading: loadMoreLoading => {
    set(state => ({
      casino: { ...state.casino, loadMoreLoading },
    }))
  },
})
