import { useEffect } from 'react'

import { api } from '../utils/api-client'
import { decryptUser } from '../utils/user'

function useAdsInfo() {
  const params = new URLSearchParams(window.location.search)

  useEffect(() => {
    const user = decryptUser()
    const search = Object.fromEntries(params.entries())

    if (search.utm_source || search.referral) {
      api.post('/crypto.php', {
        type: 'save_advertise_record',
        meta: {
          ...search,
        },
      })
    }

    if (Object.keys(search).length && !user) {
      localStorage.setItem('ads_info', JSON.stringify(search))
    }
  }, [])
}

export default useAdsInfo
