import NiceModal from '@ebay/nice-modal-react'
import { useTranslation } from 'react-i18next'

import duplicate_icon from '../../assets/common/duplicate_icon.webp'
import Modal from './modal'

const DuplicateAccountModal = () => {
  const { t } = useTranslation('duplicateAccount')

  return (
    <Modal id="duplicate-account-modal">
      <div className="v-flx-ctr">
        <img className="w-[140px] mb-4" src={duplicate_icon} alt="Duplicate" />
        <p className="text-txt text-base uppercase text-center mb-5 xl:text-lg">
          {t('title')}
        </p>
        <p className="text-[15px] text-txt text-center font-normal mb-3 xl:text-xl">
          {t('already-registered')}
        </p>
        <button className="h-[35px] bg-[#46aef7] w-[170px] rounded-sm xl:w-[190px]">
          <p className="text-[10px] text-white uppercase xl:text-xs">
            {t('contact')}
          </p>
        </button>
        <button className="h-[35px] bg-[#888] mt-3 w-[170px] rounded-sm xl:w-[190px]">
          <p className="text-[10px] text-white uppercase xl:text-xs">
            {t('got-it')}
          </p>
        </button>
      </div>
    </Modal>
  )
}

export default NiceModal.create(DuplicateAccountModal)
