import { Portal } from 'react-portal'

import loading_gif from '../../assets/common/loading.gif'
import classNames from '../../utils/classNames'

interface LoadingProps {
  solidBg?: boolean
}

const Loading = ({ solidBg }: LoadingProps) => {
  return (
    <Portal>
      <div
        className={classNames(
          'fixed w-screen h-screen z-[600] bg-[color:var(--primary-50)] flex items-center justify-center left-0 top-0',
          solidBg && 'bg-primary',
        )}>
        <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
          <img
            className="w-[180px] h-[180px] object-contain"
            src={loading_gif}
            alt="Loading GIF"
          />
        </div>
      </div>
    </Portal>
  )
}

export default Loading
