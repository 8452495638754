import type { StateCreator } from 'zustand'

import type { KycInfo, UserInfo } from '../../types/user'
import type { AllSlices } from '../types'
import type { UserSlice } from '../types/user'

export type User = UserInfo & {
  unified_balance: string
  pending_amount: string
  pending_bind: 0 | 1 | 2
  profile_picture: string | null
  isLoading: boolean
  kycInfo: KycInfo
  promo_balance: string
  show_balance: number
  wagering: {
    current: null | number
    target: null | number
  }
}

export const initialUserState: User = {
  username: '',
  nickName: '',
  register_method: 'normal',
  balance: '0.00',
  email: '',
  first_name: null,
  last_name: null,
  middle_name: null,
  phone: '',
  pic: false,
  realName: '',
  sex: '',
  region: null,
  city: null,
  postal: null,
  landline: null,
  agent_percentage: null,
  regTime: '',
  country: null,
  email_verified: '0',
  phone_verified: '0',
  birthday: '',
  is_agent: '0',
  welcome_fs: 0,
  is_verified: 0,
  kyc_lvl: 1,
  rut: null,
  verif_request_count: 0,
  verif_request_comment: '',
  is_google_auth_enabled: 0,
  unified_balance: '0.00',
  pending_amount: '0.00',
  pending_bind: 1,
  profile_picture: null,
  isLoading: true,
  ready_to_review: 0,
  viewed_surveys: null,
  coin_drop: 0,
  coin_drop_amount: 0,
  coin_drop_ids: '',
  userID: '',
  houseNumber: '',
  street: '',
  cologne: '',
  id_country: null,
  id_type: null,
  kycInfo: {
    should_kyc_status: '0',
    kyc1_status: '0',
    kyc2_status: '0',
    kyc_combine_status: '0',
    isLoading: true,
  },
  promo_balance: '0',
  show_balance: 0,
  oauth: 0,
  wagering: {
    current: null,
    target: null,
  },
}

export const createUserSlice: StateCreator<
  AllSlices,
  [],
  [],
  UserSlice
> = set => ({
  ...initialUserState,
  setProfilePicture: profilePicture =>
    set(state => ({
      user: { ...state.user, profile_picture: profilePicture },
    })),
  setIsLoading: isLoading =>
    set(state => ({ user: { ...state.user, isLoading: isLoading } })),
  setEmailVerification: verification =>
    set(state => ({
      user: {
        ...state.user,
        email_verified: verification as UserInfo['email_verified'],
      },
    })),
  setBalance: balance => {
    set(state => ({
      user: {
        ...state.user,
        unified_balance: balance.balance.toString(),
        promo_balance: balance.promo_balance.toString(),
        show_balance: balance.show_balance,
        wagering: balance.wagering,
      },
    }))
  },
  setUser: userInfo => set(state => ({ user: { ...state.user, ...userInfo } })),
  setKycInfo: kycInfo =>
    set(state => ({
      user: { ...state.user, kycInfo: { ...state.user.kycInfo, ...kycInfo } },
    })),
})
