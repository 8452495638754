import customerService from '../../assets/common/customer_service.svg'

export const CustomerServiceButton = () => {
  return (
    <button
      className="cursor-pointer transition-all duration-[0.2s] ease-[ease-in-out] flex hover:scale-105"
      id="float-customer-service"
      aria-label="Contact customer service">
      <img
        className="pointer-events-none w-[50px] h-[51.66px]"
        src={customerService}
        alt="customer service button"
      />
    </button>
  )
}
