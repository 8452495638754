import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { lazy, Suspense, useEffect, useState } from 'react'
import { Portal } from 'react-portal'

import useDimensions from '../../hooks/useDimensions'
import closeModal from '../../utils/closeModal'
import { Backdrop, Spinner } from '../common'
import Tabs from './tabs'

const Deposit = lazy(() => import('./deposit'))
const Withdraw = lazy(() => import('./withdraw'))

type WalletManagementModalProps = {
  walletManagementDefaultTab?: {
    tab?: number
  }
}

const WalletManagementModal = ({
  walletManagementDefaultTab,
}: WalletManagementModalProps) => {
  const { isDesktop } = useDimensions()
  const modal = useModal()
  const [activeTab, setActiveTab] = useState(
    walletManagementDefaultTab?.tab ?? 0,
  )

  useEffect(() => {
    setActiveTab(walletManagementDefaultTab?.tab ?? 0)
  }, [walletManagementDefaultTab])

  return (
    <Portal>
      {isDesktop && (
        <Backdrop
          zIndex={203}
          isVisible={modal.visible}
          onClick={() => closeModal(modal)}
        />
      )}
      <div
        id="wallet-management-modal"
        className="w-full h-screen max-w-[1100px] bg-primary z-[204] fixed p-0 rounded-none border-0 left-0 top-0 transition-opacity duration-300 ease-in-out xl:-translate-x-2/4 xl:-translate-y-2/4 xl:h-[unset] xl:rounded-xl xl:left-2/4 xl:top-2/4">
        <Tabs
          activeTab={activeTab}
          setActiveTab={tab => {
            setActiveTab(tab)
          }}
          hide={() => closeModal(modal)}
        />
        <Suspense fallback={<Spinner containerStyle={{ padding: '36px 0' }} />}>
          {activeTab === 0 ? <Deposit /> : <Withdraw />}
        </Suspense>
      </div>
    </Portal>
  )
}

export default NiceModal.create(WalletManagementModal)
